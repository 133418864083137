<template>
    <div id="booking-edit" v-if="doctor">
        <div class="booking-edit-banner">
            <h2 class="toolbar-title">預約掛號</h2>
        </div>

        <div class="booking-time" @click="onClinicClick">
            <span class="booking-time-hint">請選擇诊所</span>
            <span class="booking-time-v" v-text="clinicName"></span>
        </div>

        <div class="clinic-info">
            <span class="icon-gender"></span>
            <span class="clinic-name" v-text="doctor.clinicname"></span>
            <span class="doctor-name" v-text="'醫師：' + doctor.username"></span>
            <a class="phone" :href="'tel:'+doctor.contactnum">電話</a>
            <a class="gps" :href="`https://www.google.com.hk/maps/search/?api=1&query=${doctor.lat},${doctor.lng}`" target="_blank">地址</a>
        </div>

        <div class="booking-time" @click="show = true">
            <span class="booking-time-hint">請選擇就診日期</span>
            <span class="booking-time-v" v-text="bookingDateTime"></span>
        </div>

        <div class="booking-intro">
            <div class="booking-intro-hint">填寫我的問題</div>
            <textarea cols="30" rows="5" class="booking-intro-v" placeholder="請描述病情（發病時間，主要症狀），曾經治療情況和效果，想得到怎樣的幫助" v-model="note"></textarea>
        </div>
        <div class="btn-booing" @click="submitBooking"><div class="lds-default" v-if="submiting"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> {{submiting ? '正在预约...' : '確認預約'}}</div>
        <div class="booking-notice">
            預約須知：<br/>
            1、請您在預約和就診時，提供真實、有效的實名身份信息和證件。<br/>
            2、突發原因有可能導致門診時間改變，請以醫院當日公佈為准，或聯繫醫院確認。<br/>
            3、醫師門診資源有限，如預約成功後因故確定不能按時就診，請務必於就診日前一天17：00前及時取消預約，否則按違約處理，累計三次，您將無法進行預約。<br/>
            4、為保證您準時就診，請於就診當日按所預約時間段提前30分鐘到門診大廳取號，逾期作廢。
        </div>

        <van-popup
            v-model="show" 
            closeable
            position="bottom"
            :style="{ height: '30%' }"
        >
            <div style="position: relative; padding-bottom: 10px; display: flex;flex-direction: row;height: 100%;box-sizing: border-box;padding-top: 50px;">
                <div class="lds-default" v-if="loadingDate"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                <div class="dates" style="flex: 1; margin-right: 20px; height: 100%; overflow-y: scroll;">
                    <div @click="onDateItemClick(d)" :class="{'doctor-work-datetime': true, 'checked': d.checked}" v-for="(d,i) in workDates" :key="i" v-text="d.date + ' ' + d.week"></div>
                </div>
                <div class="times" style="position: relative; flex: 1; height: 100%; overflow-y: scroll;">
                    <div class="lds-default" v-if="loadingTime"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    <div @click="onTimeItemClick(d)" :class="{'doctor-work-datetime': true, 'checked': d.checked}" v-for="(d,i) in workTimes" :key="i" v-text="d.time"></div>
                </div>
            </div>
        </van-popup>

        <van-popup
            v-model="showClinic" 
            closeable
            position="bottom"
            :style="{ height: '30%' }"
        >
            <div style="position: relative; padding-bottom: 10px; display: flex;flex-direction: row;height: 100%;box-sizing: border-box;padding-top: 50px;">
                <div style="position: relative; flex: 1; height: 100%; overflow-y: scroll;">
                    <div class="lds-default" v-if="loadClinic"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    <div class="clinic-item" v-for="(clinic, i) in clinicList" :key="i" v-text="clinic.clinicname" @click="onClinicChanged(clinic)"></div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import booking_time from '@/views/mixins/booking_time'
	export default {
        mixins: [booking_time],
        data: function() {
            return {
                clinicName: '',
                doctor: this.$route.params.doctor,
                bookingDate: '',
                bookingTimeId: '',
                bookingDateTime: '',
                show: false,
                showClinic: false,
                workDates: [],
                workTimes: [],
                loadingTime: false,
                loadingDate: true,
                submiting: false,
                note: '',
                loadClinic: true,
                clinicList: [],
            }
        },
        mounted: function(){
            if(!this.doctor) {
                this.$router.push('/doctor/list')
                return
            }
            this.loadData()
        },
        methods: {
            loadData: function(){
                this.getWorkDatas({doctorId: this.doctor.userid}, (data, error)=>{
                    this.loadingDate = false
                    if(error) {
                        this._toast.warning(this, error)
                        this.show = false
                    } else {
                        this.workDates = data
                        if(this.workDates.length) {
                            this.loadingTime = true
                            this.bookingDate = this.workDates[0].date
                            this.getWorkTimes(
                            {
                                doctorId: this.doctor.userid, 
                                scheduleDate: this.workDates[0].date
                            }, (times, error)=>{
                                this.loadingTime = false
                                if(times) {
                                    this.workTimes = times
                                } else {
                                    if(error) {
                                        this._toast.warning(this, error)
                                    }
                                }
                            })
                        } else {
                            this._toast.warning(this, '預約的醫師未設置工作時間')
                            this.show = false
                        }
                    }
                })
            },
            onClinicChanged: function(clinic) {
                this.showClinic = false
                if(clinic.clinicid == this.doctor.clinicid) return
                this.doctor.userid = clinic.userid
                this.doctor.clinicname = clinic.clinicname
                this.doctor.contactnum = clinic.contacttel
                this.doctor.clinicaddr = clinic.address

                this.workDates = []
                this.workTimes = []
                this.note = ''
                this.translateX = 0

                this.loadingTime = true
                this.loadData()
            },
            onClinicClick: function() {
                this.showClinic = true
                this.$store.dispatch('getClinicListByDoctor', {globaluserid: this.doctor.globaluserid})
                .then((list) => {
                    this.clinicList = list
                    this.loadClinic = false
                })
            },
            onTimeItemClick: function(d){
               if(d.isChecked) {
                    return
                }
                this.workTimes.filter(date=>date != d).forEach(date=>date.checked = false)
                d.checked = true
                this.bookingTimeId = d.id
                this.workTimes = [...this.workTimes]
                this.bookingDateTime = this.bookingDate + ' ' + d.time
                setTimeout(() => {
                    this.show = false
                }, 500);
            },
            onDateItemClick: function(d) {
                if(d.isChecked) {
                    return
                }
                this.loadingTime = true
                this.workDates.filter(date=>date != d).forEach(date=>date.checked = false)
                d.checked = true
                this.bookingDate = d.date
                this.workTimes = []
                this.bookingDateTime = ''
                this.getWorkTimes(
                {
                    doctorId: this.doctor.userid, 
                    scheduleDate: d.date
                }, (times, error)=>{
                    this.loadingTime = false
                    if(times) {
                        this.workTimes = times
                    } else {
                        if(error) {
                            this._toast.warning(this, error)
                        }
                    }
                })
            },
            submitBooking: function(){
                if(this.submiting) return
                if(! this.bookingDateTime) {
                    this._toast.warning(this, '請選擇就診日期')
                    return
                }
                this.submiting = true
                var params = {
                    clientId: this.$store.getters.clientId,
                    doctorId: this.doctor.userid,
                    note: this.note,
                    scheduleDate: this.bookingDate,
                    scheduleID: this.bookingTimeId,
                    sourceType: 'F',
                }
                this.addBooking(params, (data, error)=>{
                    this.submiting = false
                    if(error) {
                        this._toast.warning(this, error)
                    } else {
                        this._toast.success(this, '預約成功')
                        setTimeout(() => {
                            this.$router.push('/user/booking/order')                          
                        }, 500)
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .booking-edit-banner {
        display: block;
        margin: 11px 24px 0px 24px;
        background-image:  url(../../../assets/imgs/mobile/doctor-list/img_banneryy@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 194px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .booking-edit-banner > .toolbar-title {
        display: inline-block;
        width: 334px;
        height: 91px;
        line-height: 91px;
        text-align: center;
        background-color: rgba(5, 27, 48, .4);
        font-size: 54px;
        font-weight: bold;
        color: #fff;
    }

    .be-address-tabs-w {
        overflow-x: scroll;
        height: 90px;
        margin: 16px 24px 16px 24px;
        background-color: #fff;
        padding-left: 19px;
    }

    .be-address-tabs {
        white-space: nowrap;
        display: flex;
        height: 90px;
        flex-direction: row;
        align-items: flex-end;
    }
    .be-address-tabs .be-address-tab {
        display: inline-block;
        margin-right: 48px;
        font-weight: 400;
        color: #636363;
        font-size: 28px;
        padding-bottom: 31px;
    }

    .be-address-tabs .be-address-tab.current {
        font-weight: bold;
        color: #231F20;
        font-size: 36px;
        padding-bottom: 30px;
        position: relative;
    }

    .be-address-tabs .be-address-tab.current::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 9px;
        width: 48px;
        height: 1px;
        border-radius: 5px;
        background-color: #36C4D0;
        transform: translateX(-50%);
    }

    .clinic-info {
        display: flex;
        flex-direction: row;
        background-color: #fff;
        margin: 0px 24px;
        padding: 30px 16px;
        position: relative;
        align-items: flex-end;
    }

    .clinic-info > .icon-gender {
        display: inline-block;
        width: 80px;
        height: 80px;
        background-image:  url(../../../assets/imgs/mobile/doctor-list/img_nanys@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .clinic-info > .clinic-name {
        position: absolute;
        left: 112px;
        top: 30px;
        font-size: 34px;
        color: #231F20;
        font-weight: bold;
        line-height: 32px;
    }

    .clinic-info > .doctor-name {
        display: inline-block;
        flex: 1;
        margin-left: 15px;
        font-size: 24px;
        line-height: 23px;
        color: #969696;
        font-weight: 400;
    }

    .clinic-info > .gps,
    .clinic-info > .phone {
        display: inline-block;
        width: 104px;
        height: 38px;
        line-height: 38px;
        box-sizing: border-box;
        padding-left: 43px;
        position: relative;
        font-weight: 400;
        color: #636363;
        font-size: 24px;
        background-color: #EFF4F9;
        border-radius: 8px;
        margin-left: 32px;
    }

    .clinic-info > .phone::after {
        content: '';
        position: absolute;
        left: 13px;
        top: 7px;
        background-image:  url(../../../assets/imgs/mobile/doctor-list/icon_phone@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 22px;
        height: 23px;
    }

    .clinic-info > .gps::after {
        background-image:  url(../../../assets/imgs/mobile/doctor-list/icon_diz@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        content: '';
        position: absolute;
        width: 18px;
        height: 24px;
        left: 16px;
        top: 6px;
    }

    .booking-time {
        display: flex;
        flex-direction: row;
        margin: 16px 24px;
        background-color: #fff;
        padding: 23px 16px;
        position: relative;
    }

    .booking-time::after {
        position: absolute;
        content: '';
        right: 16px;
        top: 23px;
        width: 17px;
        height: 34px;
        background-image:  url(../../../assets/imgs/mobile/doctor-list/icon_shijian@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .booking-time > .booking-time-hint {
        line-height: 34px;
        color: #969696;
        font-size: 24px;
        font-weight: 400;
    }

    .booking-time > .booking-time-v {
        margin-left: 53px;
        line-height: 34px;
        color: #231F20;
        font-size: 32px;
        font-weight: bold;
    }

    .booking-intro {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        margin: 0px 24px;
        padding: 32px 22px 38px 16px;
    }

    .booking-intro > .booking-intro-hint {
        font-weight: bold;
        color: #231F20;
        line-height: 26px;
        font-size: 28px;
    }

    .booking-intro > .booking-intro-v {
        height: 146px;
        background-color: #EBF4F6;
        padding: 17px 20px 17px 21px;
        font-weight: bold;
        color: #231F20;
        font-size: 32px;
        margin-top: 20px;
    }

    .booking-intro > .booking-intro-v::placeholder {
        color: #969696;
        font-size: 24px;
        font-weight: 400;
    }

    .btn-booing {
        width: 314px;
        height: 80px;
        background-color: #36C4D0;
        border-radius: 8px;
        line-height: 80px;
        text-align: center;
        color: #fff;
        font-size: 34px;
        font-weight: bold;
        margin: 57px auto;
        user-select: none;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .btn-booing:active {
        opacity: 0.7;
    }

    .booking-notice {
        padding: 1px 24px 48px 24px;
        font-weight: 300;
        font-size: 24px;
        line-height: 42px;
        color: #969696;
    }

    .doctor-work-datetime {
        height: 66px;
        line-height: 66px;
        padding-left: 20px;
        font-size: 32px;
        font-weight: 400;
        color: #231F20;
        margin: 0 10px;
        border-bottom: 1px solid #e7e7e7;
        box-sizing: border-box;
    }

    .doctor-work-datetime.checked {
        border: 1px solid #000;
    }

	.lds-default {
		display: inline-block;
		position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}

    .btn-booing > .lds-default {
        position: unset;
        transform: unset;
        margin-right: 10px;
    }

    .btn-booing > .lds-default div {
        background: #fff;
    }

    .clinic-item {
        height: 72px;
        line-height: 72px;
        padding: 0 12px;
        border-bottom: 1px solid #e8e8e8;
    }
</style>
